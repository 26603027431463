import revive_payload_client_4sVQNw7RlN from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/Servicos/Microsoft/AgentFin/_work/4/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_PSbK9A48ZQ from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "D:/Servicos/Microsoft/AgentFin/_work/4/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import pinia_Uphuq97G1L from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/pinia.js";
import vuex_owYp5qnaH8 from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/vuex.js";
import axios_sVCuMR6hEC from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/axios.js";
import accessor_VkPv6n47XV from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/accessor.js";
import vue_hotjar_next_client_fn9c3NKVVJ from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/vue-hotjar-next.client.js";
import vue_recaptcha_v3_uvzrnT4eW1 from "D:/Servicos/Microsoft/AgentFin/_work/4/s/plugins/vue-recaptcha-v3.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_PSbK9A48ZQ,
  chunk_reload_client_UciE0i6zes,
  pinia_Uphuq97G1L,
  vuex_owYp5qnaH8,
  axios_sVCuMR6hEC,
  accessor_VkPv6n47XV,
  vue_hotjar_next_client_fn9c3NKVVJ,
  vue_recaptcha_v3_uvzrnT4eW1
]