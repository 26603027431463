const pageMetaData = {
  layout: 'institutional',
  title: 'Fale Conosco',
  description: `
    Fale com o Alfa e tire suas principais dúvidas sobre como fazer um empréstimo consignado digital. As 
    melhores taxas e atendimento personalizado para você!
  `,
  keywords: `
    falar com o alfa; falar com a financeira alfa; telefone alfa; telefone financeira alfa; duvida emprestimo 
    consignado
  `,
}

const pageData = {
  title: 'Ajuda',
  description: `
    Tire todas as suas dúvidas em uma lista de perguntas frequentes e por meio dos nossos canais de atendimento 
    exclusivos.
  `,
  banner: {
    img: 'ajuda/help_banner.webp',
    alt: 'Banner da página de ajuda',
  },
  content: {
    channels: {
      figure: {
        img: 'ajuda/help-baloon.webp',
        alt: 'Imagem ilustrativa sobre canais de atendimento',
      },
      tabs: [
        {
          id: 0,
          title: 'Canais de Atendimento',
          description: `
            Atendimento personalizado e exclusivo. Nosso compromisso é com a excelência, a qualidade e 
            a privacidade.
          `,
        },
        {
          id: 1,
          title: 'Perguntas Frequentes',
          description: `
            O Alfa responde as principais dúvidas relacionadas ao Empréstimo Consignado, navegando pelos assuntos 
            abordados.
          `,
        },
      ],
      list: [
        {
          class: 'support',
          title: 'SAC Financeira Alfa',
          description: 'dias úteis, das 8h às 19h',
          phones: [
            {
              link: 'tel:40040044',
              value: '4004 0044',
              text: '(capitais e regiões metropolitanas)',
            },
            {
              link: 'tel:08007250044',
              value: '0800 725 0044',
              text: '(demais localidades)',
            },
          ],
        },
        {
          class: 'sales',
          title: 'Central de Vendas Financeira Alfa',
          description: 'dias úteis, das 9h às 18h',
          phones: [
            {
              link: 'tel:08003232532',
              value: '0800 323 2532',
              text: '(demais localidades)',
            },
            {
              link: 'tel:1133868659',
              value: '11 3386 8659',
              text: '(WhatsApp Consignado Privad)',
            },
          ],
        },
        {
          class: 'ombudsman',
          title: 'Ouvidoria',
          description: 'dias úteis, das 8h às 18h',
          phones: [
            {
              link: 'tel:08003452532',
              value: '0800 345 2532',
              text: null,
            },
            {
              link: 'tel:08007705140',
              value: '0800 770 5140',
              text: '(exclusivo para deficientes auditivos e de fala)',
            },
          ],
        },
        {
          class: 'unlawfulness',
          title: 'Canal de Comunicação de Indícios de Ilicitude',
          description: null,
          phones: [
            {
              link: 'tel:08007755108',
              value: '0800 775 5108',
              text: null,
            },
          ],
        },
      ],
    },
    questions: {
      title: 'Perguntas frequentes',
      description: 'Explore por assuntos e tire todas as dúvidas com perguntas já respondidas pelo Alfa!',
      tabs: [
        {
          id: 0,
          title: 'Como contratar',
        },
        {
          id: 1,
          title: 'Refinanciamento',
        },
        {
          id: 2,
          title: 'Portabilidade',
        },
        {
          id: 3,
          title: 'Open Finance',
        },
      ],
      list: [
        {
          parentId: 0,
          id: 0,
          title: 'Como solicitar o Alfa Empréstimo Consignado',
          paragraphs: [
            `
              <span>Contratação On-line Consignado Público: acesse o site</span>
              <a href="www.alfaconsignado.com.br" target="_blank">www.alfaconsignado.com.br</a>
              <span>
                , faça seu pré-cadastro e simule uma proposta de empréstimo consignado, informando o valor que 
                deseja contratar. Para uma análise completa, é necessário preencher os dados solicitados. Assim 
                você terá acesso às condições personalizadas do seu Alfa Empréstimo Consignado Digital feitas 
                de acordo com seu convênio e seu perfil.
              </span>
            `,
            `
              <span>Contratação On-line Consignado Privado: acesse o site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e faça sua simulação de empréstimo consignado, informando o valor que deseja contratar. Para uma 
                análise completa, é necessário preencher os dados solicitados. Assim você terá acesso às condições 
                personalizadas do seu Alfa Empréstimo Consignado Digital feitas de acordo com seu convênio e 
                seu perfil.
              </span>
            `,
            `<span>Precisa de ajuda? Fale com um de nossos especialistas!</span>`,
            `
              <span>Entre em contato com a central de vendas no </span>
              <a href="tel:1130332532">11 3033-2532 </a>
              <span> ou através do WhatsApp</span>
              <a href="https://wa.me/1133868659" target="_blank">11 3386-8659. </a>
              <span>Se preferir, temos especialistas distribuídos em diversos Estados do Brasil. Ligue para </span>
              <a href="tel:08003232532">0800 323 2532 </a>
              <span>e faça uma simulação.</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 1,
          title: 'Tenho restrições em meu nome. Posso solicitar um Alfa Empréstimo Consignado?',
          paragraphs: [
            `
              <span>
                A análise de restrição é realizada após cadastro das operações. A solicitação de crédito para o 
                Alfa Empréstimo Consignado passa por uma análise individual, para haver uma avaliação final.
              </span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 2,
          title: 'Quantas linhas de crédito consignado e qual prazo máximo posso contratar?',
          paragraphs: [
            `
              <span>
                A quantidade de linhas de crédito consignado varia de acordo com cada convênio, definido por 
                legislação. Sendo assim, os prazos podem variar de acordo com o convênio do qual você faz parte.
              </span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 3,
          title: 'O Alfa pede pagamento antecipado para liberar empréstimo?',
          paragraphs: [
            `
              <span>
                Atenção! O Alfa não solicita depósito antecipado para efetivar operações financeiras e não opera 
                com Chave PIX (CNPJ / e-mail / Celular) para pagamento de parcelas de empréstimos e 
                financiamentos, ainda que vencidos.
              </span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 4,
          title: 'Como solicito a segunda via do boleto do meu Alfa Empréstimo Consignado?',
          paragraphs: [
            `
              <span>Consignado Público - Entre em contato com a nossa central de atendimento no </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044" target="blank">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>Consignado Privado - Para solicitar a segunda via do seu boleto, entre no site </span>
              <a href="www.alfaconsignadoprivado.com.br" target=""_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e acesse a área logada do cliente, com CPF e senha, selecione o seu contrato e clique em 2° via 
                do boleto. Em caso de dúvidas, entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044" target="blank">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044" target="blank">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 5,
          title: 'Como solicito a segunda via do meu contrato (cédula de crédito bancário)?',
          paragraphs: [
            `
              <span>Consignado Público - Entre em contato com a nossa central de atendimento no </span>
              <a href="tel:1140440044">11 4044-0044</a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08000044">0800 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>Consignado Privado - Para solicitar a segunda via do seu boleto, entre no site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a> 
              <span>
                e acesse a área logada do cliente, com CPF e senha, clique na opção "consultas" e baixe seu 
                contrato. Em caso de dúvidas, entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044</a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044</a>
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 6,
          title: 'Como acompanho a evolução da minha dívida?',
          paragraphs: [
            `
              <span>
                Consignado Público - O que é DED (Demonstrativo da Evolução da Dívida)? O Demonstrativo da 
                Evolução da Dívida é um extrato detalhado da sua dívida. Entre em contato com a nossa central de 
                atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044</a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>Consignado Privado - Para acompanhar a evolução da sua dívida, basta entrar no site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br</a>
              <span>
                e acessar a área logada do cliente, com CPF e senha. Clique na opção “consultas” e faça o 
                acompanhamento. Em caso de dúvidas, entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044</a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a> 
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 7,
          title: 'Como faço para quitar meu Alfa Empréstimo Consignado?',
          paragraphs: [
            `
              <span>Consignado Público - Entre em contato com a nossa central de atendimento no </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>Consignado Privado - Para quitar seu contrato entre no site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e acesse a área logad do cliente, com CPF e senha. Clique na opção “consultas” e selecione o 
                contrato que deseja liquidar. Em caso de dúvidas, entre em contato com a nossa central de 
                atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044</a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 8,
          title: 'Como solicito meu IR (Informe de Rendimentos)?',
          paragraphs: [
            `
              <span>
                Consignado Público - É fácil e rápido! Entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>
                Consignado Privado - Para emitir o documento de Informe de Rendimento (IR), entre no site 
              </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e acesse a área logada do cliente, com CPF e senha. Clique na opção “IR” e baixe o documento. Em 
                caso de dúvidas, entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044</a>
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 9,
          title: 'Posso solicitar o Alfa Empréstimo Consignado sem ter margem disponível?',
          paragraphs: [
            `
              <span>
                Caso você não tenha margem, não será possível contratar um novo empréstimo, mas não fique 
                triste... é possível refinanciar seu contrato vigente ou fazer uma portabilidade.
              </span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 10,
          title: 'Como recorrer em casos de desistência?',
          paragraphs: [`<span>Prazo de até 07 dias corridos para cancelamento e devolutivas.</span>`],
        },
        {
          parentId: 0,
          id: 11,
          title: 'Como atualizar meus dados cadastrais?',
          paragraphs: [
            `
              <span>
                Consignado Público - É fácil e rápido! Entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
            `
              <span>
                Consignado Privado - Para realizar alteração nos dados cadastrais e/ou dados bancários, entre no 
                site 
              </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e acesse a área logada do cliente, com CPF e senha. Clique na opção “Atualizar Dados”. Para 
                propostas em andamento, entre em contato com a nossa central de atendimento no 
              </span>
              <a href="tel:1140440044">11 4044-0044 </a>
              <span>(capitais e regiões metropolitanas) ou </span>
              <a href="tel:08007250044">0800 725 0044 </a>
              <span>(demais localidades).</span>
            `,
          ],
        },
        {
          parentId: 0,
          id: 12,
          title: 'Devo pagar meu empréstimo consignado mesmo afastado do trabalho?',
          paragraphs: [
            `
              <span>
                Sim! O desconto das parcelas é recorrente, portanto, mesmo que afastado, o pagamento deverá 
                ocorrer via boleto. Entre em contato com o 
              </span>
              <a href="tel:1130332532">11 3033-2532 </a>
              <span>ou através do WhatsApp </span>
              <a href="https://wa.me/113386-8659" target="_blank">11 3386-8659</a>.
            `,
            `
              <span>
                Se preferir, temos especialistas distribuídos em diversos Estados do Brasil. Ligue para 
              </span>
              <a href="tel:08003232532">0800 323 2532 </a>
              <span>e solicite seu boleto.</span>
            `,
          ],
        },
        {
          parentId: 1,
          id: 13,
          title: 'O que é refinanciamento?',
          paragraphs: [
            `
              <span>
                O refinanciamento é uma solução para clientes que já possuem contratos ativos com o Alfa. Você 
                pode utilizá-lo caso esteja precisando de dinheiro ou queira diminuir o valor de parcela que 
                paga atualmente.
              </span>
            `,
            `
              <span>
                Ele é disponibilizado nos casos em que o contratante já tenha pago uma parte das parcelas do 
                seu empréstimo, gerando novas parcelas fixas em um prazo flexível, com taxas menores que cabem 
                no seu orçamento. Caso você não seja cliente Alfa, traga seu contrato com a portabilidade.
              </span>
            `,
          ],
        },
        {
          parentId: 1,
          id: 14,
          title: 'Como solicitar o refinanciamento do meu Empréstimo Consignado Alfa?',
          paragraphs: [
            `
              <span>Contratação On-line Consignado Público: acesse o site</span>
              <a href="www.alfaconsignado.com.br" target="_blank">www.alfaconsignado.com.br</a>
              <span>
                , faça seu pré-cadastro e simule uma proposta de empréstimo consignado, informando o valor 
                que deseja contratar. Para uma análise completa, é necessário preencher os dados solicitados. 
                Assim você terá acesso às condições personalizadas do seu Alfa Empréstimo Consignado Digital, 
                feitas de acordo com seu convênio e seu perfil.
              </span>
            `,
            `
              <span>Contratação On-line Consignado Privado: acesse o site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e faça sua simulação de empréstimo consignado, informando o valor que deseja contratar. Para 
                uma análise completa, é necessário preencher os dados solicitados. Assim você terá acesso às 
                condições personalizadas do seu Alfa Empréstimo Consignado Digital, feitas de acordo com seu 
                convênio e seu perfil.
              </span>
            `,
            `
              <span>Precisa de ajuda? Fale com um de nossos especialistas! Entre em contato com o </span>
              <a href="tel:1130332532">11 3033-2532 </a>
              <span>ou através do WhatsApp </span>
              <a href="https://wa.me/1133868659" target="_blank">11 3386-8659. </a>
              <span>
                Se preferir, temos especialistas distribuídos em diversos Estados do Brasil. Ligue para
              </span>
              <a href="tel:08003232532">0800 323 2532 </a>
              <span>e faça uma simulação.</span>
            `,
          ],
        },
        {
          parentId: 1,
          id: 15,
          title: 'Posso refinanciar meu contrato sem margem disponível?',
          paragraphs: [`<span>Sim, é possível refinanciar seu contrato vigente ou fazer uma portabilidade.</span>`],
        },
        {
          parentId: 2,
          id: 16,
          title: 'O que é portabilidade de crédito?',
          paragraphs: [
            `
              <span>
                A Portabilidade de Crédito permite que você transfira sua dívida contratada em outra instituição 
                para a Financeira Alfa e, dessa forma, aproveite as taxas e os prazos que temos disponíveis para 
                você. Sua solicitação poderá ser efetivada em até 5 dias úteis.
              </span>
            `,
          ],
        },
        {
          parentId: 2,
          id: 17,
          title: 'Como posso solicitar uma portabilidade de crédito para a Alfa?',
          paragraphs: [
            `
              <span>
                Consignado Público - Para solicitar uma Portabilidade de Crédito, é necessário que você entre em 
                contato com nossa equipe através do 
              </span>
              <a href="tel:1130332532">11 3033-2532</a>
              <span>ou </span>
              <a href="tel:08003232532">0800 323 2532</a>
              <span>, ou através do WhatsApp <span>
              <a href="https://wa.me/1133868659" target="_blank">11 3386-8659</a>
              <span>
                . Informe o número do contrato, o saldo devedor atualizado e os demais dados solicitados e 
                formalize sua solicitação.
              </span>
            `,
            `
              <span>
                Consignado Privado - Para solicitar uma Portabilidade de Crédito, é necessário que você entre 
                em contato com nossa equipe através do 
              </span>
              <a href="tel:1130332532">11 3033-2532 </a>
              <span>ou </span>
              <a href="tel:08003232532">0800 323 2532</a>
              <span>, ou através do WhatsApp </span>
              <a href="https://wa.me/1133868659" target="_blank">11 3386-8659</a>
              <span>. Outra opção é acessar o site </span>
              <a href="www.alfaconsignadoprivado.com.br" target="_blank">www.alfaconsignadoprivado.com.br </a>
              <span>
                e realizar a portabilidade do seu contrato de forma on-line. Informe o número do contrato, o 
                saldo devedor atualizado e os demais dados solicitados e formalize sua solicitação.
              </span>
            `,
          ],
        },
        {
          parentId: 3,
          id: 18,
          title: 'O que é Open Finance?',
          paragraphs: [
            `
              <span>
                O Open Finance mais conhecido como sistema financeiro aberto, propicia o compartilhamento 
                padronizado de dados e serviços por meio de APIs (Application Programming Interfaces) por 
                parte de instituições financeiras e demais instituições autorizadas a funcionar pelo Banco 
                Central do Brasil.
              </span>
            `,
            `
              <span>
                No caso de dados de clientes (pessoa física ou jurídica) é o cliente que decidirá quando e com 
                quem ele deseja compartilhá-los no escopo do Open Finance, desde que seja com finalidades 
                específicas e prazos determinados.
              </span>
            `,
            `
              <span>Para mais informações acesse: </span>
              <a href="https://openfinancebrasil.org.br/" target="_blank">https://openfinancebrasil.org.br/</a>
            `,
          ],
        },
        {
          parentId: 3,
          id: 19,
          title: 'Quais são os benefícios?',
          paragraphs: [
            `
              <span>
                Com o Open Finance espera-se a entrega de produtos e serviços financeiros a consumidores com 
                maior agilidade, conveniência e segurança. Além da possibilidade de integração da prestação de 
                serviços financeiros à jornada digital do consumidor, são fornecidas condições para o surgimento 
                de modelos de negócio que têm o cliente no centro.
              </span>
            `,
            `
              <span>
                Esses novos modelos, que podem envolver, por exemplo, comparadores de preços, produtos e serviços, 
                possibilitarão ao consumidor maior visibilidade e controle de sua vida financeira, bem como o 
                acesso a produtos personalizados e a condições financeiras mais vantajosas e adequadas à s suas 
                necessidades, interesses e objetivos.
              </span>
            `,
            `
              <span>Para mais informações acesse: </span>
              <a href="https://openfinancebrasil.org.br/" target="_blank">https://openfinancebrasil.org.br/</a>
            `,
          ],
        },
        {
          parentId: 3,
          id: 20,
          title: 'Como aderir?',
          paragraphs: [
            `<span>O passo a passo é simples, para aderir: <span>`,
            `<span>1 - Acesse a área do cliente com CPF e senha;</span>`,
            `<span>2 - No menu lateral acesse a opção “Open Finance”;</span>`,
            `<span>3 - Você será redirecionado para um portal de consentimento do Open Finance;</span>`,
            `
              <span>
                4 - Clique em “Login” e siga o passo a passo mostrado em tela com o preenchimento de seu CPF e 
                Senha.
              </span>
            `,
          ],
        },
      ],
    },
  },
}

export { pageMetaData, pageData }
