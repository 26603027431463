import { default as como_45contratarbTDOmDkbhQMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/como-contratar.vue?macro=true";
import { default as indexABBd11endpMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/index.vue?macro=true";
import { default as open_45financeFqNkeILZsWMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/open-finance.vue?macro=true";
import { default as portabilidadeRo6akHCiBcMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/portabilidade.vue?macro=true";
import { default as refinanciamentoSGS4VsnyIJMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/refinanciamento.vue?macro=true";
import { default as alerta_45antifraudeaZkzvu2dStMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/alerta-antifraude.vue?macro=true";
import { default as datao4BaZgC85hMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ativacao-cadastro/data.js?macro=true";
import { default as index2wPRFSwLXCMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ativacao-cadastro/index.vue?macro=true";
import { default as _91_46_46_46slug_930UFOXl78IEMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/blog/[...slug].vue?macro=true";
import { default as index7UVzKlUnhqMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/blog/index.vue?macro=true";
import { default as data5kq0Mrbu35Meta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/criacao-de-acesso/data.js?macro=true";
import { default as indexGui5SHwNIOMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/criacao-de-acesso/index.vue?macro=true";
import { default as indexFtvBKzbsSsMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/index.vue?macro=true";
import { default as como_45contratarsQ5bUjWnTXMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/como-contratar.vue?macro=true";
import { default as dataASXNYg89zXMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/data.js?macro=true";
import { default as open_45financeD0emVfhrEFMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/open-finance.vue?macro=true";
import { default as portabilidadeSj2o3dCLEKMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/portabilidade.vue?macro=true";
import { default as refinanciamentoMX8ppAhhq9Meta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/refinanciamento.vue?macro=true";
import { default as dataSnjLDNCTksMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/alerta-antifraude/data.js?macro=true";
import { default as datavGjZD0B4ehMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/data.js?macro=true";
import { default as datamnJfeknOvYMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/lgpd/data.js?macro=true";
import { default as dataoa5TxSw4t6Meta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/politica-de-privacidade/data.js?macro=true";
import { default as dataS6JvzjuKvbMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/termos-de-uso/data.js?macro=true";
import { default as LGPDt2Lpf5YTi8Meta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/LGPD.vue?macro=true";
import { default as politica_45de_45privacidadevcLufJPvbRMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/politica-de-privacidade.vue?macro=true";
import { default as portability_45errorVHAZclm4ywMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/portability-error.vue?macro=true";
import { default as quem_45somosLNPHMJ4Z1EMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/quem-somos.vue?macro=true";
import { default as indexDiAXX3ZHcoMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/simulador-de-emprestimo/index.vue?macro=true";
import { default as termos_45de_45usoLAN5XBlbfbMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/termos-de-uso.vue?macro=true";
import { default as vantagens_45e_45diferenciaisN5o535n0yjMeta } from "D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/vantagens-e-diferenciais.vue?macro=true";
export default [
  {
    name: "ajuda-como-contratar",
    path: "/ajuda/como-contratar",
    meta: como_45contratarbTDOmDkbhQMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/como-contratar.vue").then(m => m.default || m)
  },
  {
    name: "ajuda",
    path: "/ajuda",
    meta: indexABBd11endpMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-open-finance",
    path: "/ajuda/open-finance",
    meta: open_45financeFqNkeILZsWMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/open-finance.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-portabilidade",
    path: "/ajuda/portabilidade",
    meta: portabilidadeRo6akHCiBcMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/portabilidade.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-refinanciamento",
    path: "/ajuda/refinanciamento",
    meta: refinanciamentoSGS4VsnyIJMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ajuda/refinanciamento.vue").then(m => m.default || m)
  },
  {
    name: "alerta-antifraude",
    path: "/alerta-antifraude",
    meta: alerta_45antifraudeaZkzvu2dStMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/alerta-antifraude.vue").then(m => m.default || m)
  },
  {
    name: "ativacao-cadastro-data",
    path: "/ativacao-cadastro/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ativacao-cadastro/data.js").then(m => m.default || m)
  },
  {
    name: "ativacao-cadastro",
    path: "/ativacao-cadastro",
    meta: index2wPRFSwLXCMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/ativacao-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: index7UVzKlUnhqMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "criacao-de-acesso-data",
    path: "/criacao-de-acesso/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/criacao-de-acesso/data.js").then(m => m.default || m)
  },
  {
    name: "criacao-de-acesso",
    path: "/criacao-de-acesso",
    meta: indexGui5SHwNIOMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/criacao-de-acesso/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexFtvBKzbsSsMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-como-contratar",
    path: "/institutional/ajuda/como-contratar",
    meta: como_45contratarsQ5bUjWnTXMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/como-contratar.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-data",
    path: "/institutional/ajuda/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-open-finance",
    path: "/institutional/ajuda/open-finance",
    meta: open_45financeD0emVfhrEFMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/open-finance.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-portabilidade",
    path: "/institutional/ajuda/portabilidade",
    meta: portabilidadeSj2o3dCLEKMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/portabilidade.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-refinanciamento",
    path: "/institutional/ajuda/refinanciamento",
    meta: refinanciamentoMX8ppAhhq9Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/ajuda/refinanciamento.vue").then(m => m.default || m)
  },
  {
    name: "institutional-alerta-antifraude-data",
    path: "/institutional/alerta-antifraude/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/alerta-antifraude/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-data",
    path: "/institutional/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-lgpd-data",
    path: "/institutional/lgpd/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/lgpd/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-politica-de-privacidade-data",
    path: "/institutional/politica-de-privacidade/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/politica-de-privacidade/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-termos-de-uso-data",
    path: "/institutional/termos-de-uso/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/institutional/termos-de-uso/data.js").then(m => m.default || m)
  },
  {
    name: "LGPD",
    path: "/LGPD",
    meta: LGPDt2Lpf5YTi8Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/LGPD.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-privacidade",
    path: "/politica-de-privacidade",
    meta: politica_45de_45privacidadevcLufJPvbRMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/politica-de-privacidade.vue").then(m => m.default || m)
  },
  {
    name: "teste",
    path: "/portability-error",
    meta: portability_45errorVHAZclm4ywMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/portability-error.vue").then(m => m.default || m)
  },
  {
    name: "quem-somos",
    path: "/quem-somos",
    meta: quem_45somosLNPHMJ4Z1EMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/quem-somos.vue").then(m => m.default || m)
  },
  {
    name: "simulador-de-emprestimo",
    path: "/simulador-de-emprestimo",
    meta: indexDiAXX3ZHcoMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/simulador-de-emprestimo/index.vue").then(m => m.default || m)
  },
  {
    name: "termos-de-uso",
    path: "/termos-de-uso",
    meta: termos_45de_45usoLAN5XBlbfbMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/termos-de-uso.vue").then(m => m.default || m)
  },
  {
    name: "vantagens-e-diferenciais",
    path: "/vantagens-e-diferenciais",
    meta: vantagens_45e_45diferenciaisN5o535n0yjMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/4/s/pages/vantagens-e-diferenciais.vue").then(m => m.default || m)
  }
]